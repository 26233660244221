// 语音内容
<template>
  <div class="voice">
    <i-nav-bar :iTitle="title"></i-nav-bar>
    <div class="main">
      <div class="list">
        <!-- 爱豆 -->
        <div class="item">
          <div class="item_l">
            <div class="img_box">
              <img
                class="star_img"
                :src="
                  judgeImgEffective(form.starObj.img_url)
                    ? form.starObj.img_url
                    : require('./../../assets/images/ido/pic_star_default.png')
                "
                alt=""
              />
            </div>
            <span class="l_txt mgl10">{{ form.starObj.star_name }}</span>
          </div>
          <div class="item_r mgr20">
            <span class="r_txt" @click="toChoose(1)">重新选择</span>
          </div>
        </div>

        <!-- 场景 -->
        <div class="item mgt20">
          <div class="item_l">
            <div class="scene_box">
              <img
                class="scene_img"
                :src="
                  judgeImgEffective(form.sceneObj.img_url)
                    ? form.sceneObj.img_url
                    : require('./../../assets/images/scene/pic_scene_default.png')
                "
                alt=""
              />
            </div>
            <span class="l_txt mgl10"
              >{{ form.sceneObj.scene_name
              }}{{ form.sceneObj.type == 1 ? "" : "（送亲友）" }}</span
            >
          </div>
          <div class="item_r mgr20">
            <span class="r_txt" @click="toChoose(2)">重新选择</span>
          </div>
        </div>

        <div class="ido_speak">
          <div class="speak_title">
            <img
              class="tit_img"
              :src="require(`./../../assets/images/mySubmit/pic_title_img.png`)"
              alt=""
            />
          </div>
          <div class="speak_body mgt15">
            <img
              class="body_img"
              src="./../../assets/images/mySubmit/back_ipt.png"
              alt=""
            />
            <textarea
              class="ipt ipt_textarea"
              @input="handleChange"
              v-model="form.con"
              maxlength="100"
              placeholder="请输入你最想听爱豆说的话，不超过100字"
            >
            </textarea>
          </div>
        </div>
      </div>
    </div>

    <!-- 底部提交按钮 -->
    <div class="footer">
      <van-button class="footer_btn btn1" color="#111111" round @click="goBack">
        <img
          class="left_img"
          src="./../../assets/images/ido/pic_left.png"
          alt=""
        />
      </van-button>
      <van-button
        v-if="isDisabled"
        class="footer_btn btn2 mgl10"
        @click="onSubmit"
        color="#4BFFF2"
        round
        >提交
        <img
          class="left_img"
          src="./../../assets/images/ido/pic_right.png"
          alt=""
        />
      </van-button>
      <van-button
        v-else
        class="footer_btn btn3 mgl10"
        disabled
        color="#4BFFF2"
        round
        >请输入文案
      </van-button>
    </div>
  </div>
</template>

<script>
import iNavBar from "@/components/iNavBar.vue";
import { judgeImgEffective } from "@/utils/utils";
import { sendInfo } from "@/request/api";

export default {
  name: "Voice",
  components: {
    iNavBar,
  },
  data() {
    return {
      judgeImgEffective,
      title: "语音内容",
      form: {
        starObj: {
          star_id: 0,
          img_url: "",
          star_name: "",
        },
        sceneObj: {
          scene_id: 0,
          img_url: "",
          scene_name: "",
          type: 1,
        },
        con: "",
      },
      isDisabled: false,
    };
  },
  mounted() {
    console.log(
      "缓存内容",
      JSON.parse(window.localStorage.getItem("submitInfo"))
    );
    this.init();
  },
  methods: {
    init() {
      if (window.localStorage.getItem("submitInfo")) {
        this.form = JSON.parse(window.localStorage.getItem("submitInfo"));
      }
    },
    onSubmit() {
      LA.track('submit_click');
      _hmt.push(["_trackEvent", "submit_click", "click"]);
      let params = {
        star_name: this.form.starObj.star_name,
        scene_type: this.form.sceneObj.type,
        scene_name: this.form.sceneObj.scene_name,
        scene_desc: this.form.sceneObj.scene_desc,
        con: this.form.con,
      };
      this.submitInfo(params);
    },

    //  提交
    async submitInfo(params) {
      let data = await sendInfo(params);
      if (data.rs_code == 1000) {
        this.$toast("提交成功");
        window.localStorage.removeItem("submitInfo");
        this.$router.push("/success");
      }
    },

    goBack() {
      this.$router.go(-1);
    },

    // 重新选择
    // type: 1.爱豆  2.场景
    toChoose(type) {
      if (type == 1) {
        this.$router.push({
          path: "/chooseIdo",
        });
      } else if (type == 2) {
        this.$router.push({
          path: "/scene",
        });
      }
      console.log("重新选择");
    },

    // 改变输入框的内容
    handleChange() {
      console.log("改变的内容", this.form.con);
      this.isDisabled = this.form.con !== "";
    },
  },
};
</script>

<style lang="less" scoped>
.voice {
  width: 100%;
  min-height: 100%;
  background: #5257cf;
  .main {
    padding-top: 30px;
    .list {
      .item {
        width: 92%;
        height: 94px;
        margin: 0 auto;
        background: url("./../../assets/images/mySubmit/back_item.png")
          no-repeat 92% 100%;
        background-size: contain;
        display: flex;
        justify-content: space-between;
        align-items: center;
        .item_l {
          display: flex;
          align-items: center;
          margin-left: 20px;
          .img_box {
            position: relative;
            width: 55px;
            height: 55px;
            border-radius: 50%;
            background: #6f87ff;
            .star_img {
              position: absolute;
              width: 55px;
              height: 55px;
              border-radius: 50%;
              background: #6f87ff;
              object-fit: contain;
            }
          }

          .scene_box {
            position: relative;
            width: 61px;
            height: 61px;
            border: 1px solid #6f87ff;
            box-sizing: border-box;
            .scene_img {
              position: absolute;
              width: 41px;
              height: 41px;
              top: 0;
              bottom: 0;
              left: 0;
              right: 0;
              margin: auto;
            }
          }

          .l_txt {
            font-size: 14px;
            font-family: Alibaba-PuHuiTi-R, Alibaba-PuHuiTi;
            font-weight: normal;
            color: #ffffff;
          }
        }

        .item_r {
          width: 70px;
          height: 30px;
          text-align: center;
          background: url("./../../assets/images/mySubmit/back_choose.png")
            no-repeat 100% 100%;
          background-size: contain;
          display: flex;
          align-items: center;
          justify-content: center;
          .r_txt {
            font-size: 12px;
            font-family: Alibaba-PuHuiTi-B, Alibaba-PuHuiTi;
            font-weight: normal;
            color: #4bfff2;
          }
        }
      }

      .ido_speak {
        margin-top: 40px;
        .tit_img {
          height: 34px;
          margin-left: 5px;
          object-fit: cover;
        }
        .speak_body {
          position: relative;
          width: 92%;
          margin: 0 auto;
          min-height: 140px;
          .body_img {
            width: 100%;
            height: 100%;
            // position: absolute;
          }
          .ipt_textarea {
            position: absolute;
            left: 0;
            box-sizing: border-box;
            width: 100%;
            height: 100%;
            text-align: justify;
            min-height: 140px;
            background: none;
            border: 0;
            font-size: 14px;
            font-family: Alibaba-PuHuiTi-R, Alibaba-PuHuiTi;
            padding: 10px;
            padding-left: 16px;
            resize: none;
            font-weight: normal;
            //   color: #6f87ff;
            line-height: 20px;
            color: #ffffff;
            // z-index: 10;
          }
        }
      }
    }
  }

  .footer {
    box-sizing: border-box;
    position: fixed;
    display: flex;
    justify-content: center;
    align-items: center;
    bottom: 0;
    width: 100%;
    padding: 10px 20px;
    z-index: 10;

    .footer_btn {
      width: 100%;
      height: 37px;

      .van-button__text {
        font-size: 14px;
        font-family: Alibaba-PuHuiTi-M, Alibaba-PuHuiTi;
        font-weight: normal;
        color: #111111;
        line-height: 20px;
      }
    }
    .left_img {
      width: 22px;
      height: 10px;
      object-fit: contain;
    }
    .btn1 {
      position: relative;
      width: 120px;
      background: #111111;
      .van-button__text {
        font-size: 14px;
        font-family: Alibaba-PuHuiTi-M, Alibaba-PuHuiTi;
        font-weight: normal;
        color: #4bfff2;
        line-height: 20px;
      }
    }
    .btn2 {
      font-size: 14px;
      font-family: Alibaba-PuHuiTi-M, Alibaba-PuHuiTi;
      font-weight: normal;
      color: #111111;
    }
  }

  textarea::-webkit-input-placeholder {
    /* WebKit browsers */
    color: #6f87ff;
  }
  textarea:-moz-placeholder {
    /* Mozilla Firefox 4 to 18 */
    color: #6f87ff;
  }
  textarea::-moz-placeholder {
    /* Mozilla Firefox 19+ */
    color: #6f87ff;
  }
  textarea::-ms-input-placeholder {
    /* Internet Explorer 10+ */
    color: #6f87ff;
  }
}
</style>